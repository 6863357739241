"use client";
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";

import { PostCard } from "../PostCard";

import styles from "./posts.carousel.module.scss";
import { IPost } from "src/interfaces/IPost";

interface IPosts {
  posts: IPost[];
}

const PostsCarousel = ({ posts }: IPosts): JSX.Element => {
  return (
    <>
      {posts.length > 0 ? (
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop={true}
          className={styles.swiper}
        >
          {posts.map((post) => (
            <SwiperSlide className={styles.swiperSlide} key={post.uid}>
              <PostCard
                key={post.uid}
                excerpt={post.metaDescription}
                imgURL={post.image.url || ""}
                imgAltText={post.image.alt ? post.image.alt : post.title}
                link={post.url ? post.url : `/post/${post.uid}`}
                formattedDate={post.formattedDate}
                title={post.title ? post.title : String(post.metaTitle)}
                tags={post?.tags}
                datetime={post.publicationDate}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Nenhum post encontrado! :(</p>
      )}
    </>
  );
};

export { PostsCarousel };
