import Image from "next/image";
import Link from "next/link";
import { AiOutlineCalendar } from "react-icons/ai";

import styles from "./postcard.module.scss";
import { KeyTextField } from "@prismicio/client";

interface IPostCard {
  showExcerpt?: boolean;
  link: string;
  imgURL: string;
  imgAltText: string | undefined;
  tags: string[];
  formattedDate: string;
  datetime: string;
  title: string;
  excerpt: string | KeyTextField;
  priorityLoad?: boolean;
}

const PostCard = ({
  showExcerpt = true,
  link,
  imgURL,
  imgAltText,
  tags,
  formattedDate,
  datetime,
  title,
  excerpt,
  priorityLoad,
}: IPostCard): JSX.Element => {
  return (
    <article className={styles.postCard}>
      <figure>
        <Link href={link} aria-label={`Navegar para ${title}`}>
          <Image
            src={imgURL}
            alt={imgAltText ? imgAltText : title}
            width={360}
            height={202}
            object-fit="cover"
            placeholder="blur"
            blurDataURL={imgURL}
            priority={priorityLoad}
          />
        </Link>

        <div className={styles.postCategories}>
          {tags?.map((tag) => (
            <Link
              key={tag}
              href={`/tag/${tag.toLowerCase()}`}
              aria-label={`Navegar para categoria ${tag}`}
              className={styles.postTag}
            >
              {tag}
            </Link>
          ))}
        </div>
      </figure>

      <div className={styles.content}>
        <time dateTime={datetime}>
          <AiOutlineCalendar />
          {formattedDate}
        </time>

        <Link href={link} className="linkHover">
          <h2>{title}</h2>
        </Link>

        {showExcerpt && <p>{excerpt}</p>}
      </div>
    </article>
  );
};

export { PostCard };
